.challengeSubmissionsContainer {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .challengeSelection {
    margin-bottom: 20px;
  }
  
  .challengeSelection h2, h3 {
    color: #333;
  }
  
  .challengeSelection h3 {
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  
  .checkpoints {
    display: flex;
    overflow-x: auto;
    margin-bottom: 20px;
  }
  
  .checkpointButton {
    padding: 10px 20px;
    margin-right: 10px;
    background-color: #f1f1f1;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .checkpointButton.active, .checkpointButton:hover {
    background-color: #107F9A;
    color: white;
  }
  
  .submissionCard {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }
  
  .actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .actions a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .actions a:hover {
    text-decoration: underline;
  }
  
  .error {
    color: red;
  }
  