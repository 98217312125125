/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}



/* ChatAppCustom.css */
.custom-control-bar-student .lk-button[data-lk-source="screen_share"][data-lk-enabled="false"] {
  display: none;
}


  .hide-chat-toggle .lk-grid-layout-wrapper {
    height: 100%;
  }
  .hide-chat-toggle .lk-chat-toggle {
    display: none;
  }
  
  

.custom-control-bar {
  position: absolute;
  bottom: 2px;
  left: 8px;
  display: flex;
  padding: 10px 0;
  z-index: 1000;
}

.custom-button-group {
  display: flex;
  gap: 10px;
}

.custom-button {
  box-sizing: border-box;
  background-color: #1e1e1e;
  color: #fff;
  cursor: pointer;
  border: none;
  padding: .625rem 1rem;
  border-radius: 0.5rem;
  font-size: 16px;
}

.custom-button:hover {
  background-color: #373737;
}

