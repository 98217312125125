.container {
    padding: 20px;
  }
  
  .heading {
    margin-bottom: 20px;
  }
  
  .tabs {
    display: flex;
    margin-bottom: 20px;
  }
  
  .tab {
    padding: 10px 20px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .activeTab {
    background-color: #107F9A;
    color: white;
  }
  
  .recordingsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
  }
  
  .recording {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .recordingDetails {
    margin-bottom: 10px;
  }
  
  .downloadButton {
    padding: 10px 15px;
    background-color: #107F9A;
    color: white;
    border: none;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
  
  .downloadButton:hover {
    background-color: #0b6c82;
  }
  
  .searchBar {
    margin: 20px 0;
  }
  
  .searchBar input {
    width: 100%;
    max-width: 600px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
  }
  