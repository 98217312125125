/* AdminAnalytics.module.css */

.analyticsContainer {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .analyticsHeader {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-size: 24px;
    font-weight: bold;
  }
  
  .statsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .statBox {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    flex: 1 1 30%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .statBoxTitle {
    margin-bottom: 10px;
    font-size: 18px;
    color: #555;
  }
  
  .statBoxValue {
    font-size: 24px;
    color: #111;
    font-weight: bold;
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }