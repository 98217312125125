@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Add this CSS to your global stylesheet */

/* Add this CSS to your global stylesheet */

/* Apply styles to all scrollbars across the project */
*::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}



/* Apply styles to all scrollbars across the project using the variable */
*::-webkit-scrollbar-track {
  background: #f3f4f6;
} 

*::-webkit-scrollbar-thumb {
  background-color: #aeaeae; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #f1f1f1; /* Adds a border around the thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovered */
}

/* Apply padding-right to account for scrollbar width */
body {
  overflow-y: scroll; /* Ensures scrollbar is always visible */
}

/* Adjust for different screen sizes */
@media (max-width: 600px) {
  body {
      padding-right: 0; /* Remove padding for smaller screens */
  }
}
