/* BrowseSessions.module.css */

.session-content {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.session-content h1 {
  margin-top: 20px;
  font-size: 20px; /* Set h1 font size to 20px */
  font-weight: bold;
}

.session-content h2,
.session-content h3,
.session-content h4,
.session-content h5,
.session-content h6 {
  margin-top: 20px;
  font-weight: bold;
}

.session-content ul {
  list-style-type: disc; /* Ensures bullets are shown */
  padding-left: 20px; /* Adds space before bullets */
  margin-top: 10px; /* Adds some margin above the list */
}

.session-content li {
  margin-bottom: 8px; /* Adds space between list items */
}

.session-list {
  max-height: 80vh; /* Adjust based on your design */
  overflow-y: auto;
}


