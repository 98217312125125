.manageChallengesContainer {
  padding: 20px;
  background-color: #f4f7f6;
  border-radius: 8px;
}

.manageChallengesContainer h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #333;
}

.challengeGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.challengeCard {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.challengeDetails h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.challengeDetails p {
  margin: 5px 0;
  color: #555;
}

.cardActions {
  display: flex;
  justify-content: flex-end;
}

.cardActions button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 10px;
}

.editButton {
  background-color: #107F9A;
  color: #fff;
}

.deleteButton {
  background-color: #e74c3c;
  color: #fff;
}

/* Modal Styles */
.modalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  margin: auto;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

.modalOverlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkpointsContainer {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.checkpointsContainer h3 {
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.checkpointsList {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.checkpointItem {
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.checkpointItem.active {
  background-color: #107F9A;
  color: white;
}

.formGroup {
  margin-bottom: 20px;
}

.formGroup label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 1rem;
  color: #555;
}

.inputField, .textareaField {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.textareaField {
  min-height: 120px;
  resize: vertical;
}

.submitButton {
  padding: 12px 20px;
  background-color: #107F9A;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  display: block;
  width: 100%;
}

.rewardsInputContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.addButton {
  padding: 8px 12px;
  background-color: #107F9A;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.rewardsList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}

.rewardTag {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.removeTag {
  margin-left: 8px;
  cursor: pointer;
  color: #ff4d4f;
}

.imagePreview {
  margin-top: 10px;
  max-height: 200px;
  border-radius: 8px;
  border: 1px solid #ddd;
}
