/* AdminReports.module.css */

.reportsContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
  }
  
  .reportsContainer h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .reportList {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .reportItem {
    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
  
  .reportItem p {
    margin: 5px 0;
    color: #555;
  }
  
  .reportItem button {
    padding: 8px 12px;
    background-color: #ff6b6b;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reportItem button:hover {
    background-color: #ff4c4c;
  }
  