/* StudentSignUp.module.css */

.container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    height: fit-content; /* Full viewport height */
  }
  
  .leftColumn {
    height: 100%;
    flex: 1; /* Occupy 50% of viewport width */
    background-color: #ffffff; /* White background */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 16px;
  }
  
  .header {
    width: 100%;
    max-width: 500px;
  }.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Darker overlay */
  }
  
  .textOverlay {
    position: absolute;
    width: 700px;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: #fff;
    padding: 0 16px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Add subtle shadow to text */
  }
  
  .textOverlay h2 {
    font-size: 24px;
    margin-bottom: 16px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Add shadow to heading */
  }
  
  .textOverlay p {
    font-size: 16px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Add shadow to paragraph */
  }
  
  .goBackIcon {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 32px; /* Adjust the width of the icon */
    height: auto; /* Ensure height adjusts proportionally */
  }
  
  .goBackIcon img {
    width: 100%; /* Ensure the image takes the full width of its parent */
    height: auto; /* Maintain aspect ratio */
  }
  
  .progressBar {
    padding-top: 24px;
    margin-top: 20px;
  }
  
  .h1 {
    font-size: 24px;
    font-style: normal;
    margin-bottom: 20px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }

  
  .subHeading {
    text-align: center;
    margin: auto;
    max-width: 380px;
    font-size: 14px;
    margin-bottom: 40px;
  }
  
  .chooseLabel {
    font-size: 16px;
    font-weight: 400;
    opacity: 80%;
    margin-bottom: 16px;
  }
  
  .accountOptions {
    margin-bottom: 16px;
  }
  
  .accountOption {
    position: relative; /* Ensure relative positioning for select indicator */
    border: 1px solid #ccc; /* Border style */
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 16px 16px;
    cursor: pointer;
    transition: border-color 0.3s ease-in-out;
  }
  
  .accountOption:hover {
    border-color: #1574bb; /* Change border color on hover */
  }
  
  .accountOptionSelected {
    border-color: #1574bb; /* Selected option border color */
  }
  
  .selectIndicator {
    position: absolute;
    top: 50%; /* Adjust position */
    right: 16px; /* Adjust position */
    transform: translateY(-50%);
    width: 16px; /* Size of the indicator */
    height: 16px; /* Size of the indicator */
    border: 1px solid #1574bb; /* Blue outline when not selected */
    border-radius: 50%; /* Circle shape */
    background-color: #fff;
  }
  
  .selectIndicatorSelected {
    background-color: #1574bb; /* Blue color when selected */
  }
  
  .accountOptionTitle {
    font-size: 16px;
    font-weight: semi-bold;
    margin-bottom: 8px;
  }
  
  .continueButton {
    background-color: var(--primary-color); /* Blue background */
    color: #fff;
    border: none;
    padding: 12px 0;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 8px;
    margin-top: 0px !important;
    width: 100%;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
  }
  
  .continueButton:hover {
    background-color: var(--primary-color-hover); /* Darker blue on hover */
  }
  
  .signIn {
    font-size: 14px;
    text-align: center;
  }
  
  .signInLink {
    color: var(--error-color); /* Blue color for link */
    text-decoration: none;
  }
  
  .signInLink:hover {
    text-decoration: underline;
  }
  
  .progressBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-top: 24px;
  }
  
  .progressStep {
    width: 32%;
    height: 4px;
    background-color: #ccc;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.30);
  }
  
  .progressStepActive {
    background-color: #1574BB;
  }
  

  .rightColumn {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .imageWrapper {
    width: 100%;
    height: calc(100% - 24px);
    padding-right: 12px;
    box-sizing: border-box;
  }
  
  .imageContainer {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #000;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .imageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlay-bg);
  }
  
  .blackOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black-overlay);
  }
  .textOverlay {
    position: absolute;
    width: 700px;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: #fff;
    padding: 0 16px;
  }
  
  .textOverlay h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .textOverlay p {
    font-size: 16px;
  }
  
  .featuresList {
    margin-top: 16px;
    padding-left: 0;
    list-style: none;
  }
  
  .featuresListItem {
    margin-bottom: 16px;
  }
  
  .featureHeader {
    display: flex;
    align-items: center;
  }
  
  .checkmark {
    font-size: 24px;
    margin-right: 8px;
    color: var(--success-color);
  }
  
  .boldText {
    font-size: 18px;
  }
  
  .nonBoldText {
    margin-top: 4px; /* Add some space between heading and description */
    font-size: 16px;
    padding-left: 32px; /* Align description under the heading by adding padding */
  }
  
  
  
  
  /* Additional CSS for StudentSignUp form */
  
  .signupForm {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .formRow {
    display: flex;
    justify-content: space-between;
  }
  
  .formGroup {
    position: relative;
    flex: 1;
    width: 100%;
    margin-bottom: 16px;
  }
  .firstRow{
    margin-right: 16px;
  }
  
  .careerField {
    margin-bottom: 16px;
    padding: 8px;
  }
  
  .passwordLabel {
    margin-top: 16px;
  }
  
  .formGroupLast {
    margin-right: 0;
  }
  
  .label {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .inputField {
    box-sizing: border-box;
    width: 100%;
    padding: 8px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .passwordWrapper {
    position: relative;
    width: 100%;
  }
  
  .passwordToggle {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 16px;
    color: #888;
  }
  
  .policyRow {
    display: flex;
    align-items: center;
    text-align: left;
  }
  
  .policyRowCheckbox {
    margin-right: 8px;
  }
  
  .policyRowLabel {
    font-size: 14px;
  }
  
  .policyRowLink {
    color: #1574BB;
    text-decoration: none;
  }
  
  .policyRowLink:hover {
    text-decoration: underline;
  }
  
  .googleSignupButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    padding: 12px 0;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 8px;
    width: 100%;
    border-radius: 4px;
  }
  
  .googleSignupButtonIcon {
    margin-right: 8px;
  }
  
  .buttonSpace {
    margin-bottom: 16px;
  }
  
  .errorMessage {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  
  .signupErrorMessage {
    color: red; /* Change color as needed */
    font-size: 16px !important;
    font-weight: 500;
    text-align: center; /* Center the text */
    margin-top: 0px !important;
  }
  


  /* Toggle button container */
.roleSwitch {
    display: flex;
    margin-bottom: 64px;
    border: 1px solid var(--primary-color); /* Light gray border */
    border-radius: 8px; /* Rounded corners */
    overflow: hidden; /* Ensures children do not break the round corners */
  }
  
  /* Individual role buttons */
  .roleButton {
    flex: 1; /* Each button takes equal width */
    padding: 10px 20px;
    cursor: pointer;
    text-align: center;
    background-color: white; /* Default inactive background */
    color: var(--primary-color);
    border: 1.5px var(--primary-color); /* No additional border */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for color changes */
  }
  
  /* Active role button */
  .activeRole {
    background-color: var(--primary-color); /* Dark text for inactive state */
    color: #ffffff; /* White text for active state */
    border-radius: 8px;
  }
  
  /* Hover effect for inactive buttons */


  .logo {
    width: 200px; /* Adjust the width as necessary */
    height: auto; /* Maintain aspect ratio */
  }
  .logoDiv{
    display: flex;
    justify-content: center;
  }
  
  /* Forgot password link styles */
.forgotPassword {
    display: inline-block;
    text-align: right;
    margin-top: 5px;
    color: #ff6347; /* Choose a color similar to the example */
    font-size: 0.875rem;
    text-decoration: none;
  }
  
  .forgotPassword:hover {
    text-decoration: underline;
  }
  

  @media (max-width: 1200px) {
    .header {
        max-width: 400px;
    }
  
    .textOverlay {
        width: 400px;
    }
  }
  
  @media (max-width: 992px) {
    .container {
      flex-direction: column;
    }
  
    .leftColumn,
    .rightColumn {
      flex: none;
      width: 100%;
      padding: 0;
    }
  
    .header {
      max-width: 100%;
      padding: 20px;
    }
  
    .textOverlay {
      width: 100%;
      max-width: 250px;
      padding: 0 8px;
  
    }
  
  }
  
  @media (max-width: 576px) {
    .h1 {
        font-size: 24px;
    }
  
    .subHeading {
        font-size: 12px;
    }
  
    .accountOptionTitle {
        font-size: 14px;
    }
  
    .textOverlay h2 {
        font-size: 20px;
    }
  
    .textOverlay p, .featuresListItem {
        font-size: 14px;
    }
  }
/* SignIn.module.css */
.spinnerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the overlay is on top */
}

.logoSpinnerOverlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.75); /* Semi-transparent background */

  left: 0;
  z-index: 1000; /* Ensure the spinner is on top */
}

.hiddenImage {
  display: none;
}

.visibleImage {
  display: block;
}

/* Other styles from your SignIn.module.css */

/* Add this to ensure proper alignment without expanding the clickable area */
.formRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Adjust the positioning of formGroup for better alignment */


/* Ensure proper spacing and alignment of the formGroup */
.formGroupLast {
  width: 100%;
  margin-bottom: 16px;
}


.logoSpinnerOverlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure the spinner is on top */
}

/* Add custom scrollbar styles for this component */
.customScrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.customScrollbar::-webkit-scrollbar-track {
  background: #fefefe; /* Background color of the scrollbar track */
}

.customScrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #f1f1f1; /* Adds a border around the thumb */
}

.customScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovered */
}
