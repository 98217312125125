/* StudentSignUp.module.css */

.container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    height: fit-content; /* Full viewport height */
  }
  
  .leftColumn {
    height: 100%;
    flex: 1; /* Occupy 50% of viewport width */
    background-color: #ffffff; /* White background */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 16px;
  }
  
  .header {
    width: 100%;
    max-width: 500px;
  }
  
  .goBackIcon {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 32px; /* Adjust the width of the icon */
    height: auto; /* Ensure height adjusts proportionally */
  }
  
  .goBackIcon img {
    width: 100%; /* Ensure the image takes the full width of its parent */
    height: auto; /* Maintain aspect ratio */
  }
  
  
  .h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .continueButton {
    background-color: var(--primary-color); /* Blue background */
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 8px;
    margin-top: 0px !important;
    width: 45%;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
  }
  
  .continueButton:hover {
    background-color: var(--primary-color-hover); /* Darker blue on hover */
  }
  
  
  
  .rightColumn {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .imageWrapper {
    width: 100%;
    height: calc(100% - 24px);
    padding-right: 12px;
    box-sizing: border-box;
  }
  
  .imageContainer {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #000;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .imageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlay-bg);
  }
  
  .blackOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black-overlay);
  }
  
  .textOverlay {
    position: absolute;
    width: 700px;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: #fff;
    padding: 0 16px;
  }
  
  .textOverlay h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .textOverlay p {
    font-size: 16px;
  }
  
  .featuresList {
    margin-top: 16px;
    padding-left: 0;
    list-style: none;
  }
  
  .featuresListItem {
    margin-bottom: 16px;
  }
  
  .featureHeader {
    display: flex;
    align-items: center;
  }
  
  .checkmark {
    font-size: 24px;
    margin-right: 8px;
    color: var(--success-color);
  }
  
  .boldText {
    font-size: 18px;
  }
  
  .nonBoldText {
    margin-top: 4px; /* Add some space between heading and description */
    font-size: 16px;
    padding-left: 32px; /* Align description under the heading by adding padding */
  }
  
  
  /* Additional CSS for StudentSignUp form */
  
  .signupForm {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .formRow {
    display: flex;
    justify-content: space-between;
  }
  
  .formGroup {
    position: relative;
    flex: 1;
    width: 100%;
    margin-bottom: 16px;
  }
  .firstRow{
    margin-right: 16px;
  }
  
 
  
  .label {
    display: block;
    text-align: left;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .inputField {
    box-sizing: border-box;
    width: 100%;
    padding: 8px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .errorMessage {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  
  


  /* Toggle button container */
  
  /* Hover effect for inactive buttons */


  .logo {
    width: 200px; /* Adjust the width as necessary */
    height: auto; /* Maintain aspect ratio */
  }
  .logoDiv{
    display: flex;
    justify-content: center;
  }
  
  
  .successMessage{
    color: #118d2c;
  }


  @media (max-width: 1200px) {
    .header {
        max-width: 400px;
    }
  
    .textOverlay {
        width: 400px;
    }
  }
  
  @media (max-width: 992px) {
    .container {
      flex-direction: column;
    }
  
    .leftColumn,
    .rightColumn {
      flex: none;
      width: 100%;
      padding: 0;
    }
  
    .header {
      max-width: 100%;
      padding: 20px;
    }
  
    .textOverlay {
      width: 100%;
      max-width: 250px;
      padding: 0 8px;
  
    }
  
  }
  
  @media (max-width: 576px) {
    .h1 {
        font-size: 24px;
    }
  
    .subHeading {
        font-size: 12px;
    }
  
    .accountOptionTitle {
        font-size: 14px;
    }
  
    .textOverlay h2 {
        font-size: 20px;
    }
  
    .textOverlay p, .featuresListItem {
        font-size: 14px;
    }
  }
  
  
/* StudentForgotPassword.module.css */
.spinnerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the overlay is on top */
}

/* Other styles from your StudentForgotPassword.module.css */
/* Add custom scrollbar styles for this component */
.customScrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.customScrollbar::-webkit-scrollbar-track {
  background: #fefefe; /* Background color of the scrollbar track */
}

.customScrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #f1f1f1; /* Adds a border around the thumb */
}

.customScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovered */
}
