/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 22px;
  left: 28px;
  top: 24px;
  z-index: 2000; /* Ensure it appears above other content */
}

.menu-item {
  cursor: pointer;
}

/* Hide burger button on larger screens */
@media (min-width: 1024px) {
  .bm-burger-button {
    display: none;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover */
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* Sidebar wrapper styles */
.bm-menu-wrap {
  position: fixed;
  top: 0; /* Ensure it starts at the top */
  left: 0; /* Ensure it starts from the left */
  height: 100%;
  width: 231px !important;
  z-index: 1500; /* High z-index to appear above other content */
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

/* Styling of overlay */
.bm-overlay {
  position: fixed; /* Cover the entire screen */
  top: 0; /* Start from the top */
  left: 0; /* Start from the left */
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1400; /* Ensure it appears above all but the menu */
}

.bm-item:hover {
  color: #ffffff;
}

/* Hides the logo when screen size is less than 900px */
@media (max-width: 1024px) {
  .lg\\:block {
    display: none;
  }
}
