.loader{
  padding-top: 12px;

}


.container {
    padding: 20px;
  }
  
  .userSections {
    display: flex;
    justify-content: space-between;
  }
  
  .section {
    flex: 1;
    margin: 10px;
  }
  
  .searchBar {
    margin-bottom: 10px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .list {
    list-style-type: none;
    padding: 0;
  }
  
  .listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    border-radius: 4px;
  }
  
  .name {
    font-weight: bold;
  }
  
  .email {
    color: gray;
    margin-top: 4px;
  }
  
  .buttonGroup {
    display: flex;
    gap: 10px;
  }
  
  .viewButton {
    background-color: blue;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .viewButton:hover {
    background-color: darkblue;
  }
  
  .deleteButton {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .deleteButton:hover {
    background-color: darkred;
  }
  
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 600px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .userDetails {
    padding: 20px;
  }
  
  .profileImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .emailDetail {
    margin: 8px 0;
  }
  
  .downloadButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .downloadButton:hover {
    background-color: #0056b3;
  }
  
  .bio {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .detail {
    font-size: 14px;
    font-weight: 500;
    margin: 4px 0;
  }
  
  .closeButton {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 20px;
  }
  
  .closeButton:hover {
    background-color: #5a6268;
  }
  


.userSections {
  display: flex;
  justify-content: space-between;
}

.section {
  flex: 1;
  margin: 10px;
}

.searchBar {
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.list {
  list-style-type: none;
  padding: 0;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 4px;
}

.name {
  font-weight: bold;
}

.email {
  color: gray;
  margin-top: 4px;
}

.buttonGroup {
  display: flex;
  gap: 10px;
}

.viewButton, .toggleAccessButton, .deleteButton {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  color: white;
}

.viewButton {
  background-color: blue;
}

.viewButton:hover {
  background-color: darkblue;
}

.toggleAccessButton {
  background-color: orange;
}

.toggleAccessButton:hover {
  background-color: darkorange;
}

.deleteButton {
  background-color: red;
}

.deleteButton:hover {
  background-color: darkred;
}

.modal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  position: fixed;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 600px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.userDetails {
  padding: 20px;
}

.profileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.emailDetail {
  margin: 8px 0;
}

.downloadButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 8px;
}

.downloadButton:hover {
  background-color: #0056b3;
}

.bio {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail {
  font-size: 14px;
  font-weight: 500;
  margin: 4px 0;
}

.closeButton {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;
}

.closeButton:hover {
  background-color: #5a6268;
}

.toggleFreeSessionButton {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}

.toggleFreeSessionButton:hover {
  background-color: #45a049;
}