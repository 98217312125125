.minScreen{
  height: 100%;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
    33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
    66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
    100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}



.title {
  font-size: 24px;
  font-weight: 600;
  padding-left: 24px;
  padding-bottom: 16px;
}

.videoChatContainer {
  padding: 20px;
  /* max-width: 1600px; */
  margin: 0 auto;
}

.contentContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.videoContainer {
  flex: 3;
  background: #000;
  height: 90vh;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  display: flex; /* Ensures video container behaves properly */
  justify-content: center;
  align-items: center;
}
@media (max-width: 1200px) {
  .videoContainer {
    border: 1px solid #ccc; /* Reverting to original border */
  }
}


.videoContainer video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* Ensure video is on top */
}

.custom-control-bar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #2d2d2d;
  border-radius: 8px;
}

.custom-button-group {
  display: flex;
  gap: 12px;
}

.custom-button {
  padding: 10px 18px;
  background-color: #4c4c4c;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.custom-button:hover {
  background-color: #5e5e5e;
}



  

.chatContainer {
  flex: 1;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 90vh;
  padding: 20px;
}

.chatHeader {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.headerProfilePicture {
  border-radius: 50%;
  margin-right: 10px;
  width: 40px;
  height: 40px;
}

.headerName {
  font-size: 18px;
  font-weight: 600;
  flex-grow: 1;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  position: relative; /* Ensure it doesn't affect the whole page */
}

.message {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px 0;
  border-radius: 8px;
}

.myMessage {
  align-self: flex-end;
  justify-content: flex-end;
  border: 1px solid var(--border-color);
  max-width: fit-content;
  word-break: break-all;
  white-space: pre-wrap;
  margin-left: auto;
  background-color: var(--background-color);
}

.theirMessage {
  align-self: flex-end;
  justify-content: flex-end;
  word-break: break-all;
  white-space: pre-wrap;
  border: 1px solid var(--border-color);
  max-width: fit-content;
  background-color: var(--background-color);
}

.messageProfilePicture {
  border-radius: 50%;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.messageText {
  flex-grow: 1;
}

.timestamp {
  font-size: 0.75rem;
  color: gray;
  margin-left: 10px;
}



.messageForm {
  padding-top: 16px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border-color);
}

.messageInput {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.sendButton {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sendButton:hover {
  background-color: var(--primary-color-hover);
}

.detailsContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  height: 340px;
}

.tabSection {
  flex: 3;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.notesSection {
  flex: 1;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box; /* Ensures padding is included in height */
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  color: black;
}

.activeTab {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.tabContent {
  padding: 8px;
  flex: 1;
  overflow-y: auto;
}

.description {
  margin-bottom: 20px;
}

.fileAttachments {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.fileGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.fileButton {
  background-color: #e0f7fa;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  margin: 4px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  display: flex;
  align-items: center;
}

.fileUploadForm {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.fileInput {
  padding: 8px;
}

.uploadButton {
  background: var(--primary-color);
  color: #fff;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.notesHeader {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  opacity: 0.6;
}

.notesHeaderMain {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 24px;
}

.noteBubble {
  background: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  word-wrap: break-word; /* Prevents text overflow */
}

.textarea {
  width: 100%;
  height: 80px;
  padding: 8px 12px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.submitButton {
  background: var(--primary-color);
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  align-self: flex-start;
}

.noteButton {
  display: flex;
  justify-content: flex-end;
}

.chatTab {
  flex-grow: 1;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  background-color: white;
  color: black;
  border-radius: 25px;
  margin: 0 2px;
}

.activeChatTab {
  background-color: var(--primary-color);
  color: white;
}

.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 9999;
}


.fullScreenButton {
  position: absolute;
  top: 10px;  /* Move the button to the top */
  left: 10px;  /* Align the button to the left */
  background: #111111;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  z-index: 10000;
}
.fullScreenButton:hover {

  background: #585656;
  z-index: 9999;
}

.recordingButtonGroup {
  position: absolute;
  top: 10px;
  left: 60px; /* Adjusted to the right of the full-screen button */
  display: flex;
  gap: 10px;
}

.recordingButton {
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #111111;
  color: white;
}

.recordingButton:hover {
  background: #585656;
}


@media (max-width: 1200px) {
  .detailsContainer {
    flex-direction: column;
    height: auto;
  }

  .contentContainer {
    flex-direction: column;
  }

  .videoContainer {
    height: 300px; /* Adjusted for small screens */
  }

  .notesSection {
    height: auto;
  }

  .submitButton {
    width: 100%;
  }
  .participantList{
    margin-left: 0px !important;

  }
}

.participantsButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #111111;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  z-index: 10000;
}

.participantsButton:hover {
  background: #585656;
  color: white;

}


.participantList {
  margin-left: -30px;
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.participantList ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.participantList li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #2d2d2d;
}

.participantName {
  padding-right: 8px;
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.participantActions {
  display: flex;
  gap: 10px;
}
.priorityChatInfo {
  background-color: #ffebcc;
  color: #d35400;
  padding: 10px;
  border: 1px solid #e67e22;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}


.participantActions .muteIcon {
  color: #ffcc00;
  cursor: pointer;
}

.participantActions .unmuteIcon {
  color: #00cc66;
  cursor: pointer;
}

.participantActions .removeIcon {
  color: #ff6666;
  cursor: pointer;
}

.participantActions .muteIcon:hover,
.participantActions .unmuteIcon:hover,
.participantActions .removeIcon:hover {
  opacity: 0.7;
}
