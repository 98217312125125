/* CustomModal.module.css */

.modal-content {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }
  
  .modal-content h1 {
    margin-top: 20px;
    font-size: 20px; /* Set h1 font size to 20px */
    font-weight: bold;
  }
  
  .modal-content h2,
  .modal-content h3,
  .modal-content h4,
  .modal-content h5,
  .modal-content h6 {
    margin-top: 20px;
    font-weight: bold;
  }
  
  .modal-content ul {
    list-style-type: disc; /* Ensures bullets are shown */
    padding-left: 20px; /* Adds space before bullets */
    margin-top: 10px; /* Adds some margin above the list */
  }
  
  .modal-content li {
    margin-bottom: 8px; /* Adds space between list items */
  }
  