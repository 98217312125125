.editSkills {
    max-width: 700px;
    display: flex;
    flex-direction: column;
  }
  
  .editSkills h2 {
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .editSkills p {
    font-size: 14px;
    color: #000000;
    margin-bottom: 10px;
  }
  
  .saveChangesButton {
    display: flex;
    align-self: flex-end; /* Aligns the button to the right side */
    margin-top: 20px; /* Adds some space between the section above and the button */
    padding: 16px 24px;
    margin: 12px;
    border: none;
    border-radius: 8px;
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
  }
  
  .saveChangesButton:hover {
    background-color: var(--primary-color-hover);
  }
  
  .priceContainer {
    margin-top: 20px;
  }
  
  .priceInput {
    width: 50%;
    padding: 12px;
    margin-top: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .availabilityContainer {
    margin-top: 20px;
    margin-bottom: 16px;
  }
  
  .availabilityContainer h3 {
    margin-bottom: 16px;
  }
  
  .dayContainer {
    margin-bottom: 15px;
    /* display: flex; */
    align-items: center;
  }
  
  .dayContainer input[type="checkbox"] {
    margin-right: 12px;
  }
  
  .shiftContainer {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }
  
  .timeSelectContainer {
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-left: 8px;
  }
  
  .timeSelect {
    margin: 0 4px;
    padding: 4px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .timeInput {
    margin: 0 5px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .addShiftButton {
    margin-left: 12px;
    background-color: transparent;
    color: var(--primary-color);
    border: none;
    cursor: pointer;
  }
  
  .removeShiftButton {
    background-color: transparent;
    border: none;
    color: #ff0000;
    font-size: 18px;
    cursor: pointer;
    margin-left: 4px;
  }
  

  .note {
    margin: 10px 0;
    padding: 10px;
    background-color: #fff4c2;
    border-left: 4px solid #ffc107;
    border-radius: 4px;
    font-size: 14px;
    color: #856404;
  }
  
  .warningTip {
    font-weight: bold;
  }
  