.serviceContainer {
  max-width: 800px;
}

.heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.peContainer p {
  font-size: 14px;
  color: #000000;
  margin-bottom: 10px;
}

.addButton {
  background-color: #fff;
  color: var(--primary-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid var(--primary-color);
  padding: 16px 24px;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 20px;
}

.addButton:hover {
  background-color: var(--primary-color-hover);
  color: #fff;
}

.servicesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.serviceCard {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid #e0e0e0; /* Slight border for better separation */
}

.cardHeader {
  position: absolute; /* Position absolutely to overlay */
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  background-color: rgba(16, 127, 154, 0.8); /* Increase opacity */
  border-radius: 8px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2); /* Add a slight border */
}

.icon {
  cursor: pointer;
  font-size: 16px;
  color: #ffffff; /* Ensure icons are visible on any background */
}

.cardImage {
  min-height: 240px;
  background: #f0f0f0;
}

.cardImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imagePlaceholder {
  width: 100%;
  height: 100%;
  background: #ccc;
}

.cardContent {
  padding: 20px;
}

.serviceTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.truncatedDescription {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}

.skillButtons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.skillButton {
  background: #f0f0f0;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  cursor: pointer;
}

.skillButton:hover {
  background: var(--primary-color);
  color: #fff;
}

.servicePrice {
  font-size: 18px;
  font-weight: 700;
  color: var(--primary-color);
  margin-top: 10px;
  text-align: right; /* Align price to the right */
}

.modal {
  position: fixed;
  max-height: 90vh;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  padding: 20px;
  overflow-y: auto; /* Enable vertical scrolling */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 3000;
}

.modalHeader {
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalHeadings {
  font-size: 16px;
  font-weight: 400;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modalContent {
  margin: 20px 0;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  background-color: var(--primary-color);
  color: white;
  padding: 16px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: var(--primary-color-hover);
}

.imageUploadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 172px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  margin: 20px 0;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.imageUploadContainer:hover {
  background-color: #e0e0e0;
}

.imageInput {
  display: none;
}

.imageUploadLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.imagePreview {
  width: 277px;
  height: 172px;
  object-fit: cover;
  border-radius: 8px;
}

.uploadPlaceholder {
  text-align: center;
}

.uploadIcon {
  font-size: 24px;
  color: #ccc;
  margin-bottom: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.imageUploadContainer:hover .uploadIcon {
  opacity: 1;
}

.inputGroup {
  margin-bottom: 15px;
}

.input,
.textarea {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: 8px;
  font-size: 14px;
}

.textarea {
  resize: none;
  height: 100px;
}

.searchContainer {
  margin-top: 8px;
  position: relative;
  width: 100%;
}

.searchInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none; /* No border on top to connect with input */
  border-radius: 0 0 5px 5px; /* Rounded corners on the bottom only */
  max-height: 150px;
  overflow-y: auto;
  background: #fff;
  z-index: 1000; /* Ensure it is above other elements */
}

.dropdownItem {
  padding: 10px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.addedSkills {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 20px;
}

.skill {
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  margin: 4px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.added {
  background-color: #e0f7fa;
}

.limitMessage {
  color: red;
  margin-top: 10px;
}

.recommendedSkills {
  margin-top: 8px;
  background-color: var(--secondary-background-color);
  padding: 20px;
  border-radius: 16px;
  border: solid 1.5px var(--black-overlay);
}

.recommendedSkills h3 {
  margin-bottom: 10px;
}

.skillsList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skillsList .skill {
  background-color: #f0f0f0;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.errorText {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}
