.createChallengeContainer {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.createChallengeHeader {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.inputField,
.textareaField {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.textareaField {
  min-height: 100px;
  resize: vertical;
}

.coverImageContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkpointSection {
  margin-top: 20px;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.checkpointHeader {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.checkpointsScrollable {
  max-height: 200px;
  overflow-y: auto;
}

.checkpointItem {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: #f7f7f7;
}

.checkpointItem:hover {
  background-color: #e0e0e0;
}

.submitButton {
  width: 100%;
  padding: 12px;
  background-color: #107F9A;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;
}

.submitButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.richTextEditor .ql-container {
  height: 200px;
  overflow-y: auto;
}

.rewardsInputContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.addButton {
  padding: 8px 12px;
  background-color: #107F9A;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.rewardsList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 10px;
}

.rewardTag {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.removeTag {
  margin-left: 8px;
  cursor: pointer;
  color: #ff4d4f;
}

.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  outline: none;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 500px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
