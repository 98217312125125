.container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .searchBar {
    margin-top: 8px;
    margin-bottom: 32px;
    padding: 8px;
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
  }
  
  .list {
    list-style-type: none;
    padding: 0;
  }
  
  .listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    border-radius: 4px;
  }
  
  .instructorInfo {
    flex: 1;
    margin-right: 10px;
  }
  
  .emailDetail {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  .buttonGroup {
    display: flex;
    gap: 12px;
  }
  
  .downloadButton {
    background-color: blue;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    width: 200px;
  }
  
  .downloadButton:hover {
    background-color: darkblue;
  }
  
  .approveButton {
    background-color: green;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .approveButton:hover {
    background-color: darkgreen;
  }
  
  .disapproveButton {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .disapproveButton:hover {
    background-color: darkred;
  }
  