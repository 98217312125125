@tailwind base;
@tailwind components;
@tailwind utilities;





/* src/styles/global.css */
:root {
  --font-family: 'Inter', sans-serif;
  --primary-color: #107F9A;
  --primary-color-hover: #0c657b;
  --background-color: #ffffff;
  --secondary-background-color:#F7F8FA;
  --error-color: red;
  --text-color: #000000;
  --border-color: rgba(0, 0, 0, 0.15);;
  --hover-border-color: #107F9A;
  --success-color: #118d2c;
  --light-green-bg: #e0f7ea;
  --overlay-bg: rgba(16, 127, 154, 0.6);
  --black-overlay: rgba(0, 0, 0, 0.50);
  --instructor-message-bg-color: var(--light-green-bg);
  --student-message-bg-color: #fff9c4;
  --message-border-radius: 10px;
}

/* Global styles */
* {
  margin: 0;
  
  padding: 0;
  font-family: var(--font-family);
}

.custom-color {
  background-color: var(--primary-color);
}

.custom-color:hover {
  background-color: var(--primary-color-hover);
}

.text-primary-color {
  color: var(--primary-color);
}

.border-primary-color {
  border-color: var(--primary-color);
}

