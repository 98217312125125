/* src/pages/Onboarding/InstructorAuthenticate.module.css */

.container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    height: fit-content; /* Full viewport height */
}

.leftColumn {
    height: 100%;
    flex: 1; /* Occupy 50% of viewport width */
    background-color: var(--background-color); /* White background */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 16px;
}

.text{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
    max-width: 80%;
    margin: 0 auto;
}

.header {
    width: 100%;
    max-width: 500px;
    text-align: center;
}

.goBackIcon {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 32px; /* Adjust the width of the icon */
    height: auto; /* Ensure height adjusts proportionally */
}

.goBackIcon img {
    width: 100%; /* Ensure the image takes the full width of its parent */
    height: auto; /* Maintain aspect ratio */
}

.progressBar {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 20px;
}

.progressStep {
    width: 32%;
    height: 4px;
    background-color: #ccc;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.30);
}

.progressStep.active {
    background-color: var(--primary-color);
}

.h1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.svgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 64px; /* Add spacing between the heading and the SVG */
}

.svgBackground {
    background-color: var(--light-green-bg); /* Light green background */
    border-radius: 50%; /* Make the background circular */
    padding: 16px; /* Padding around the SVG */
}

.emailAuthSvg {
    width: 64px; /* Adjust the width of the SVG */
    height: 64px; /* Adjust the height of the SVG */
}

.svgHead {
    margin-top: 16px;
}

.svgHead h2 {
    font-size: 24px;
}

.svgHead p {
    max-width: 75%;
    margin: auto;
    margin-top: 8px;
    font-size: 16px;
}

.continueButton {
    opacity: 30%; /* Apply opacity */
    background-color: var(--primary-color); /* Blue background */
    color: #fff;
    border: none;
    padding: 12px 0;
    font-size: 16px;
    margin-bottom: 8px;
    width: 100%;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.continueButton i {
    margin-right: 8px; /* Space between the icon and text */
}

.continueButton:disabled {
    cursor: not-allowed; /* Change cursor to indicate it's not clickable */
}

.rightColumn {
    flex: 1; /* Occupy 50% of viewport width */
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageWrapper {
    width: 100%;
    height: calc(100% - 24px); /* Full height minus 32px padding (16px top and 16px bottom) */
    padding-right: 12px; /* 16px padding on the right */
    box-sizing: border-box; /* Include padding in width/height calculation */
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #000;
    border-radius: 8px;
    overflow: hidden;
}

.imageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlay-bg); /* Blue layer with 60% opacity */
}

.blackOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black-overlay); /* Black layer with 40% opacity */
}

.textOverlay {
    position: absolute;
    width: 700px;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: #fff;
    padding: 0 16px;
  }
  
  .textOverlay h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .textOverlay p {
    font-size: 16px;
  }
  
  .featuresList {
    margin-top: 16px;
    padding-left: 0;
    list-style: none;
  }
  
  .featuresListItem {
    margin-bottom: 16px;
  }
  
  .featureHeader {
    display: flex;
    align-items: center;
  }
  
  .checkmark {
    font-size: 24px;
    margin-right: 8px;
    color: var(--success-color);
  }

  
.boldText {
    font-size: 18px;
  }
  
  .nonBoldText {
    margin-top: 4px; /* Add some space between heading and description */
    font-size: 16px;
    padding-left: 32px; /* Align description under the heading by adding padding */
  }
.note {
    font-size: 14px !important;
    margin-top: 64px;
    margin-bottom: 4px;
    color: #FF7A80;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .header {
        max-width: 400px;
    }

    .textOverlay {
        width: 400px;
    }
}

@media (max-width: 992px) {
    .container {
      flex-direction: column;
    }
  
    .leftColumn,
    .rightColumn {
      flex: none;
      width: 100%;
      padding: 0;
    }
  
    .header {
      max-width: 100%;
      padding: 20px;
    }
  
    .textOverlay {
      width: 100%;
      max-width: 250px;
      padding: 0 8px;
  
    }
  
  }

@media (max-width: 576px) {
    .h1 {
        font-size: 24px;
    }

    .subHeading {
        font-size: 12px;
    }

    .accountOptionTitle {
        font-size: 14px;
    }

    .textOverlay h2 {
        font-size: 20px;
    }

    .textOverlay p,
    .featuresListItem {
        font-size: 14px;
    }
}

/* Add custom scrollbar styles for this component */
.customScrollbar::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .customScrollbar::-webkit-scrollbar-track {
    background: #fefefe; /* Background color of the scrollbar track */
  }
  
  .customScrollbar::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 2px solid #f1f1f1; /* Adds a border around the thumb */
  }
  
  .customScrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the scrollbar thumb when hovered */
  }
  