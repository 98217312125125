.container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .heading {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .searchBar {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .list {
    list-style: none;
    padding: 0;
  }
  
  .listItem {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .sessionInfo {
    margin-bottom: 10px;
    color: #555;
  }
  
  .sessionInfo p {
    margin: 5px 0;
  }
  
  .buttonGroup {
    display: flex;
    justify-content: flex-end;
  }
  
  .pendingButton {
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }
  
  .pendingButton:hover {
    background-color: #0056b3;
  }
  