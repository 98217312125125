.nav {
    width: 250px;
    background-color: #333;
    color: white;
    padding: 1rem;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .navList {
    list-style-type: none;
    padding: 0;
  }
  
  .navItem {
    cursor: pointer;
    padding: 0.5rem 1rem;
    background-color: #444;
    margin-bottom: 1rem;
    border-radius: 4px;
  }
  
  .navItem:hover {
    background-color: #555;
  }
  