/* Existing styles */
.container {
  padding: 20px;
}

.searchBar {
  margin-bottom: 16px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}


.instructorList {
  list-style-type: none;
  padding: 0;
}

.instructorItem {
  margin-bottom: 16px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
}

.instructorInfo {
  margin-bottom: 10px;
}

.servicesGrid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.serviceCard {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  width: 300px; /* Adjust card width */
}

.cardHeader {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  background-color: rgba(16, 127, 154, 0.8);
  border-radius: 8px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.icon {
  cursor: pointer;
  font-size: 16px;
  color: #FFFFFF;
}

.cardImage {
  min-height: 240px;
  background: #f0f0f0;
}

.cardImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imagePlaceholder {
  width: 100%;
  height: 100%;
  background: #ccc;
}

.cardContent {
  padding: 20px;
}

.serviceTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
}

.truncatedDescription {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 16px;
  height: 64px;
}

.skillButtons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.skillButton {
  background: #fff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  cursor: pointer;
}

.skillButton:hover {
  background: var(--primary-color);
  color: #fff;
}

/* Modal and form styles */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  max-width: 800px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
  }

.form {
  display: flex;
  flex-direction: column;
}

.inputGroup {
  margin-bottom: 12px;
}

.inputGroup label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.inputGroup input,
.inputGroup textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.imagePreview {
  margin-top: 10px;
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
}

.saveButton,
.closeButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.saveButton {
  background: var(--primary-color);
  color: white;
}

.closeButton {
  background: #ccc;
  color: black;
}


/* Add the existing styles here... */

.searchContainer {
  position: relative;
}

.searchInput {
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.dropdownItem {
  padding: 8px;
  cursor: pointer;
}

.dropdownItem:hover {
  background: #f0f0f0;
}

.addedSkills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.skill {
  background: #eee;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
}

.skill:hover {
  background: #ddd;
}
