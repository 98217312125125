.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .heading {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .message {
    font-size: 1.2em;
    color: #666;
    text-align: center;
    max-width: 600px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Add custom scrollbar styles for this component */
.customScrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.customScrollbar::-webkit-scrollbar-track {
  background: #fefefe; /* Background color of the scrollbar track */
}

.customScrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #f1f1f1; /* Adds a border around the thumb */
}

.customScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovered */
}
