.loader{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.searchBar {
  margin-top: 32px;
  margin-bottom: 16px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

.list {
  list-style-type: none;
  padding: 0;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 4px;
}

.sessionInfo {
  flex: 1;
}

.sessionName {
  padding-top: 4px;
  padding-bottom: 12px;
  font-weight: bold;
}

.buttonGroup {
  display: flex;
  gap: 10px;
}

.editButton,
.approveButton,
.disapproveButton {
  background-color: blue;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
}

.editButton:hover {
  background-color: darkblue;
}

.approveButton {
  background-color: green;
}

.approveButton:hover {
  background-color: darkgreen;
}

.disapproveButton {
  background-color: red;
}

.disapproveButton:hover {
  background-color: darkred;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 80%; /* Adjust width as needed */
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.modalChange{
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 40%; /* Adjust width as needed */
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.userDetails {
  padding: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.currentIns{
  padding: 12px 0px;
}

.error {
  color: red;
  font-size: 14px;
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  margin-bottom: 5px;
  font-weight: bold;
}

.formGroup input,
.formGroup textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.imageUploadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 172px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  margin: 20px 0;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.modalHeadings {
  font-size: 16px;
  font-weight: 400;
}

.imageUploadContainer:hover {
  background-color: #686767;
}

.imageInput {
  display: none;
}

.imageUploadLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.imagePreview {
  width: 277px;
  height: 172px;
  object-fit: cover;
  border-radius: 8px;
}

.uploadPlaceholder {
  text-align: center;
}

.uploadIcon {
  font-size: 24px;
  color: #ccc;
  margin-bottom: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.imageUploadContainer:hover .uploadIcon {
  opacity: 1;
}

.inputGroup {
  margin-top: 16px;
  width: 100%;
}

.input,
.textarea,
.select {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: 8px;
  font-size: 14px;
  margin-bottom: 16px;
}

.textarea {
  resize: none;
  height: 100px;
}

.searchContainer {
  margin-top: 8px;
  position: relative;
  width: 100%;
}

.searchInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

.skillsContainer {
  margin-top: 10px;
}

.addedSkill {
  margin-top: 16px;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.addedSkill {
  background-color: #e0f7fa;
}

.submitButton {
  background-color: var(--primary-color);
  color: white;
  padding: 16px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.submitButtonDiv {
  display: flex;
  justify-content: flex-end;
}

.submitButton:hover {
  background-color: var(--primary-color-hover);
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.timeSelectContainer {
  display: flex;
  align-items: center;
}

.timeSelect {
  margin: 0 4px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.timeContainer {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.dateTimeBox {
  display: flex;
  gap: 16px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
  max-height: 150px;
  overflow-y: auto;
}

.dropdownItem {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.saveButton {
  background-color: var(--primary-color);
  color: white;
  padding: 16px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.saveButton:hover {
  background-color: var(--primary-color-hover);
}

.changeInstructorButton {
  background-color: orange;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 10px;
}

.changeInstructorButton:hover {
  background-color: darkorange;
}

.inputGroup {
  margin-top: 16px;
  width: 100%;
  position: relative; /* For positioning the dropdown */
}

.input,
.textarea,
.select {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: 8px;
  font-size: 14px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
  max-height: 150px;
  overflow-y: auto;
}

.dropdownItem {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.revokedAccess {
  color: red;
  font-weight: bold;
}
/* Add the following styles to InstructorServices.module.css */

.closeButton {
  background-color: #ccc;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
}

.closeButton:hover {
  background-color: #999;
}

.pendingButton{
  background-color: rgb(163, 130, 130);
  border-radius: 8px;
  padding: 8px 16px;
}
.quill {
  margin-top: 8px;
  margin-bottom: 16px;
}

.ql-container {
  border-radius: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 14px;
}

.ql-toolbar {
  border-radius: 8px 8px 0 0;
}

.inputGroup {
  margin-top: 16px;
  width: 100%;
  position: relative; /* For positioning the dropdown */
}