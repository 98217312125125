/* src/pages/Onboarding/ChooseAccountType.module.css */

.container {
  display: flex;
  flex-direction: row;
  height: fit-content;
  min-height: 100vh;
}

.leftColumn {
  flex: 1;
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  position: relative;
  padding: 16px;
}

.header {
  width: 100%;
  max-width: 500px;
}

.goBackIcon {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 32px;
  height: auto;
}

.goBackIcon img {
  width: 100%;
  height: auto;
}

.progressBar {
  padding-top: 24px;
  margin-top: 20px;
}

.h1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.subHeading {
  text-align: center;
  margin: auto;
  max-width: 380px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 56px;
}

.chooseLabel {
  font-size: 18px;
  text-align: left;
  font-weight: 400;
  opacity: 80%;
  margin-bottom: 8px;
}

.accountOptions {
  margin-bottom: 16px;
}

.accountOption {
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 16px 16px;
  text-align: left;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;
}

.accountOption:hover {
  border-color: var(--hover-border-color);
}

.accountOptionSelected {
  border: 2px solid var(--hover-border-color);
}

.selectIndicator {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 1px solid var(--hover-border-color);
  border-radius: 50%;
  background-color: #fff;
}

.selectIndicatorSelected {
  background-color: var(--hover-border-color);
}

.accountOptionTitle {
  margin-bottom: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.continueButton {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 12px 0;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 8px;
  width: 100%;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

.continueButton:hover {
  background-color: var(--primary-color-hover);
}

.signIn {
  font-size: 14px;
  text-align: center;
}

.signInLink {
  color: #FF7A80;
  text-decoration: none;
}

.signInLink:hover {
  text-decoration: underline;
}

.progressBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.progressStep {
  width: 32%;
  background-color: #ccc;
  height: 4px;
  border: 1px solid rgba(0, 0, 0, 0.30);
  border-radius: 4px;
}

.progressStepActive {
  background-color: var(--primary-color);
}

.rightColumn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  width: 100%;
  height: calc(100% - 24px);
  padding-right: 12px;
  box-sizing: border-box;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 8px;
  overflow: hidden;
}

.imageContainer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-bg);
}

.blackOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black-overlay);
}
.textOverlay {
  position: absolute;
  width: 700px;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: #fff;
  padding: 0 16px;
}

.textOverlay h2 {
  font-size: 24px;
  margin-bottom: 16px;
}

.textOverlay p {
  font-size: 16px;
}

.featuresList {
  margin-top: 16px;
  padding-left: 0;
  list-style: none;
}

.featuresListItem {
  margin-bottom: 16px;
}

.featureHeader {
  display: flex;
  align-items: center;
}

.checkmark {
  font-size: 24px;
  margin-right: 8px;
  color: var(--success-color);
}

.boldText {
  font-size: 18px;
}

.nonBoldText {
  margin-top: 4px; /* Add some space between heading and description */
  font-size: 16px;
  padding-left: 32px; /* Align description under the heading by adding padding */
}


.errorMessage {
  color: var(--error-color);
  font-size: 12px;
  margin-bottom: 8px;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .header {
    max-width: 400px;
  }

  .textOverlay {
    width: 400px;
  }
}

@media (max-width: 992px) {
  .container {
    flex-direction: column;
  }

  .leftColumn,
  .rightColumn {
    flex: none;
    width: 100%;
    padding: 0;
  }

  .header {
    max-width: 100%;
    padding: 20px;
  }

  .textOverlay {
    width: 100%;
    max-width: 250px;
    padding: 0 8px;

  }

}

@media (max-width: 900px) {

}

@media (max-width: 576px) {
  .h1 {
    font-size: 24px;
  }

  .subHeading {
    font-size: 12px;
  }

  .accountOptionTitle {
    font-size: 14px;
  }
}



/* ChooseAccountType.module.css */

/* Add custom scrollbar styles for this component */
.customScrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.customScrollbar::-webkit-scrollbar-track {
  background: #fefefe; /* Background color of the scrollbar track */
}

.customScrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #f1f1f1; /* Adds a border around the thumb */
}

.customScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovered */
}
